import Swal from 'sweetalert2'

/* Importanco las utilizades comunes */

import CookieUtils from '../../resources/js/common/cookie_utils.js';
import WebSocketUtils from '../../resources/js/common/ws_utils.js';
import Utils from '../../resources/js/common/utils.js';
import {protocol} from "./common/store/config";

require('./bootstrap.js');
require('./dataTables.rowsGroup.js');


/**
 *
 * @param {string} title
 * @param {string} msg
 * @param {string['success', 'error', 'warning', 'info', 'question']} type This is a parameter
 * @param {string[ 'top-left',   'top',   'top-right',
 *                 'center-left','center','center-right',
 *                 'bottom-left','bottom','bottom-right']} position This is a parameter
 * @param {number} timer Time to fade out
 * @returns {void}
 */
var notificationCounter = 0;

function showNotification(msg, title = '', type = 'success', position = 'bottom-right', timer = 2500) {
    title = title ?? '';
    type = type ?? 'success';
    position = position ?? 'bottom-right';
    timer = timer ?? 1500;

    notificationCounter++;
    var id = `notification-${notificationCounter}`;

    $('#notification-wrapper')
        // $('body')
        .append(`<div id="${id}" class="card notification ${id} ${type} ${position}">
            ${title && title.length ? `<div class="card-header">${title}</div>
                    <div class="card-body">${msg}</div>` : `<div class="card-header only-notification-header">${msg}</div>`}
        </div>`)

    let actualNotificationNumber = $(`.notification.${position}`).length;
    if (actualNotificationNumber) {
        $(`.notification.${position}:not(#${id})`).each(function (index) {
            if (position.includes('top')) {
                let top = Number($(this).css('top').replace('px', ''))
                $(this).css('top', `calc(${top + $(`#${id}`).height()}px + 0.5rem)`)
            } else if (position.includes('bottom')) {
                let bottom = Number($(this).css('bottom').replace('px', ''))
                $(this).css('bottom', `${bottom + $(`#${id}`).height() + 10}px`)
            }
        })
    }
    // Desaparicion
    if (timer != -1) {
        setTimeout(function () {
            $(`#${id}`).addClass('fade')
            setTimeout(function () {
                $(`#${id}`).remove()
                //TODO: Al borrar, recolocar las tarjetas
            }, 150)
        }, timer)
    }

}

function showVirtualPropertiesEdit(model, modelId) {
    $.ajax({
        url: `${location.origin}/virtual-properties-edit/${model}/${modelId}`, method: 'GET'
    }).then(res => {
        $('body').append(`
            <div id="temp-modal" class="modal" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Propiedades Virtuales</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            ${res}
                        </div>
                    </div>
                </div>
            </div>
        `)
        var tempModal = $('#temp-modal')

        $(tempModal).modal('show');

        $(tempModal).on('hidden.bs.modal', function (event) {
            $(tempModal).remove()
        })
        $('#virtual-properties-save-button').click(function(e){
            e.preventDefault()
            e.stopPropagation()

            const serializedData = $('#virtual-properties-edit-form').serializeArray()
            // Array de objetos name:value a objeto key:value
            let data = serializedData.reduce((temp = {}, {name, value}) => ({...temp, [name] : value}), {})

            $.ajax({
                url: `${location.origin}/api/utils/virtual-properties-save/${model}/${modelId}`,
                method: 'POST',
                data
            }).then(res => {
                console.log('Propiedades virtuales actualizadas correctamente');
            })

        })
    })
}


Object.assign(window, {
    'Swal': Swal,
    'formatNumber': function (number, maxDigits = 4) {
        if (isNaN(number)) return null;
        return number.toLocaleString('de-DE', {
            minimumFractionDigits: 0, maximumFractionDigits: maxDigits
        });
    }, 'decodeHtml': function (html) {
        return $('<div>').html(html).text();
    }, 'confirmForm': function (event) {
        event.preventDefault();
        Swal.fire({
            icon: 'warning',
            title: '¿Estas seguro?',
            showCancelButton: true,
            confirmButtonColor: '#28A745',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar'
        }).then(result => {
            if (result.isConfirmed) {
                $($(event.target).parents('form')[0]).submit()
            }
        })
    }, 'sendFormWithAjax': function (form) {
        var actionUrl = form.attr('action');
        var formType = form.attr('method');
        $.ajax({
            type: formType, url: actionUrl, data: form.serialize(), // serializes the form's elements.
            success: function (data) {
                console.log('Success ( ^^)b'); // show response from the php script.
            }
        });
    },
    'showNotification': showNotification,
    'showVirtualPropertiesEdit': showVirtualPropertiesEdit,
    'formatDateTime': function formatDateTime(date){
        if(!date) return null;
        if(typeof date == 'string') date =  new Date(date);

        return date.toLocaleString().replaceAll(',','')
    },
    'formatDate': function formatDate(date){
        if(!date) return null;
        if(typeof date == 'string') date =  new Date(date);

        return date.toLocaleDateString().replaceAll(',','')
    }
})
$(document).ready(function () {
    $('.chosen').chosen();
});


